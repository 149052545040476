import { css as styled } from '@emotion/react';

export default (theme, isDesktop) => styled`
	// ${isDesktop ? 'visibility: hidden;' : 'visibility: visible;'}
	visibility: visible;
	top: 0;
	right: 0;
	position: fixed;
	left: 0;
	z-index: 100;
	background-color: #EA5758;
	box-shadow: 0 12px 10px rgba(0, 0, 0, 0.1);

	.nav-mobile {
		width: 400px;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
		background-color: #EA5758;
		z-index: 98;
		overflow: hidden;

		&-hide {
			transform: translate3d(100vw, 0, 0);
		}

		&-show {
			transform: translate3d(calc(100vw - 400px), 0, 0);
		}
		
		${theme.breakpoints.down('sm')} {
		  &-hide {
			transform: translate3d(100vw, 0, 0);
		  }

		  &-show {
			transform: translate3d(calc(0), 0, 0);
		  }
        }

		.nav-mobile-wrapper {
			margin: auto;
			margin-top: 10rem;
			width: 50%;

			@media (orientation: landscape) {
				${theme.breakpoints.down(1000)} {
					margin-top: 4rem;
				}
			}

			.nav-mobile-wrapper-link {
				text-decoration: none;
				user-select: none !important;
				-webkit-tap-highlight-color: transparent;

				.nav-mobile-wrapper-link-text {
					font-size: 28px;
					text-transform: capitalize;
					font-weight: 600;
					letter-spacing: 2px;
					color: white;
					font-family: Josefin Sans, sans-serif;
					transition: 0.3s ease;
					user-select: none;
					-webkit-tap-highlight-color: transparent;
				}

				.nav-mobile-wrapper-link-text:hover {
					color: #1F212D;
				}

				${theme.breakpoints.down(400)} {
					margin-left: 5vw;
				}

				${theme.breakpoints.down(360)} {
					margin-left: 10vw;
				}
			}

			.nav-mobile-wrapper-link:focus {
				outline: none;
				background: none;
			}

			.nav-mobile-wrapper-link:active {
				outline: none;
				background: none;
			}
		}
	}
`;
