import { createContext } from 'react';

// const isBrowser = () => ![typeof window, typeof document].includes('undefined');

export const GlobalContext = createContext({
	document: typeof document !== 'undefined' ? document : undefined,
	window: typeof window !== 'undefined' ? window : undefined
});

export const CMSContext = createContext({});
