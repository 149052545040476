import { css as styled } from '@emotion/react';

export default theme => styled`
	html,
	body {
		margin: 0;
		padding: 0;
	}

	h1,
	h2 {
		font-family: 'itc-avant-garde-gothic-pro', serif;
		font-weight: bold;
	}

  h3, h4, h5, h6 {
		font-family: "itc-avant-garde-gothic-pro", sans-serif;
	}

	button,
	p,
	div {
		font-family: "itc-avant-garde-gothic-pro", sans-serif !important;
	}

	p {
		font-weight: 300 !important;
		color: #747474;
	}

	@media screen and (max-width: 600px) {
		h1 {
			font-size: 3.25rem;
		}

		h2 {
			font-size: 2.5rem;
		}

	    h3 {
	        font-size: 2rem;
	    }
	}

`;
