import React, { useRef, useState, useEffect } from 'react';
import styles from './styles';
import NavbarMobile from './NavbarMobile';
import { useTheme } from '@mui/material/styles';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faBars, faTimes } from '@fortawesome/pro-light-svg-icons';
import { Link as RouterLink } from 'gatsby';
import {
	Toolbar,
	Link,
	Container,
	Box,
	Fade,
	IconButton,
	Button,
	Popover,
	List,
	ListItem,
	ListItemButton,
	ListItemText
} from '@mui/material';
import Image from '../Image';

const Navbar = () => {
	const theme = useTheme();

	// Mobile navigation slider
	const [isVisible, setVisible] = useState(false);
	const [logoVisible, setLogoVisible] = useState(false);

	const [menuOpen, setMenuOpen] = useState(false);

	const isBrowser = () => ![typeof window, typeof document].includes('undefined');

	const data = useStaticQuery(graphql`
		{
			logo: file(relativePath: { eq: "logo.png" }) {
				childImageSharp {
					gatsbyImageData(layout: FIXED, height: 100)
				}
			}
		}
	`);

	useEffect(() => {
		isBrowser()
			? window.location.pathname === '/'
				? window.addEventListener('scroll', () => {
						window.scrollY > 300 ? setLogoVisible(true) : setLogoVisible(false);
				  })
				: setLogoVisible(true)
			: setLogoVisible(true);
	}, []);

	return (
		<Box css={styles(theme)}>
			<Container className="nav" maxWidth="xl">
				<Toolbar className="nav-wrapper">
					<Box
						className="nav-container"
						sx={{ display: { xs: 'flex', md: 'flex', width: '100%' } }}
					>
						{/* Mobile navigation box*/}
						<Box className="nav-wrapper">
							{/*This element is here to allow a centered logo and a right aligned menu icon*/}

							<Fade in={logoVisible}>
								<Box classname="logo-top">
									<Link className="logo-link" href="/">
										{/* <GatsbyImage
										image={data?.logo?.childImageSharp?.gatsbyImageData}
										alt={'logo'}
									/> */}
										{/* {
											isBrowser() && window.location.pathname !== '/' ?
											<img className="logo" src="/img/play-soft-white.svg" width="60" alt="Play." />
											:
											<img className="logo" src="/img/play-coral.svg" width="60" alt="Play." />
										} */}

										<img
											className="logo"
											src="/img/play-coral.svg"
											width="60"
											alt="Play."
										/>

										{/* <span className="logo-dot">.</span> */}
									</Link>
								</Box>
							</Fade>
						</Box>

						<Box>
							<IconButton
								className="nav-wrapper-logo"
								color="inherit"
								onClick={() => {
									setVisible(!isVisible);
									setMenuOpen(!menuOpen);
								}}
							>
								{!menuOpen ? (
									<FontAwesomeIcon className="menu-icon" icon={faBars} />
								) : (
									<FontAwesomeIcon className="menu-icon" icon={faTimes} />
								)}
							</IconButton>
						</Box>
					</Box>
				</Toolbar>
			</Container>
			<NavbarMobile
				open={menuOpen}
				onClick={() => setMenuOpen(false)}
				visibility={isVisible}
			/>
		</Box>
	);
};

const NavLink = ({ name, href, scrollToId, dropdown, button }) => {
	const $button = useRef(null);
	const [open, setOpen] = useState(false);

	const handleClick = () => {
		if (href) return navigate(href);
		if (window?.location?.pathname !== '/') navigate(`/#${scrollToId}`);
		const yOffset = -150;
		const element = document?.getElementById(scrollToId);
		const y = element?.getBoundingClientRect()?.top + window?.pageYOffset + yOffset;
		window?.scrollTo({ top: y, behavior: 'smooth' });
	};

	return (
		<>
			<Button
				onMouseOver={dropdown ? () => setOpen(true) : undefined}
				ref={$button}
				component="a"
				variant={button ? 'contained' : 'text'}
				key={name}
				className={`nav-wrapper-item
				${button ? 'nav-wrapper-item-button' : 'nav-wrapper-item-text'}`}
				onClick={handleClick}
			>
				{name}
				{/*<Button*/}
				{/*	variant={button ? "contained" : "text"}*/}
				{/*	className={`${*/}
				{/*		activeNavLinkId === name*/}
				{/*			? "nav-wrapper-item-button activeClass"*/}
				{/*			: "nav-wrapper-item-button"*/}
				{/*	} ${*/}
				{/*		button*/}
				{/*			? "nav-wrapper-item-button-contained"*/}
				{/*			: "nav-wrapper-item-button-text"*/}
				{/*	} `}>*/}
				{/*	{name}*/}
				{/*</Button>*/}
			</Button>
			{dropdown && (
				<Popover
					open={open}
					// style={{pointerEvents: 'none'}}
					anchorEl={$button.current}
					PaperProps={{ onMouseLeave: () => setOpen(false) }}
					onClose={() => setOpen(false)}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left'
					}}
				>
					<List>
						<ListItem disablePadding>
							<ListItemButton
								component={RouterLink}
								to="/accommodation/wensum-cottage"
							>
								<ListItemText primary="Wensum Cottage" />
							</ListItemButton>
						</ListItem>
						<ListItem disablePadding>
							<ListItemButton
								component={RouterLink}
								to="/accommodation/victoria-cottage"
							>
								<ListItemText primary="Victoria Cottage" />
							</ListItemButton>
						</ListItem>
					</List>
				</Popover>
			)}
		</>
	);
};

export default Navbar;
