import React, { useState } from "react"
import styles from "./styles"
import {navLinks} from "../navItems"
import {useTheme} from "@mui/material/styles"
import {
	Box,
	Typography, Stack, useMediaQuery, Container, Backdrop
} from "@mui/material"
import {Link} from "gatsby"

const NavbarMobile = ({open, onClick, visibility}) => {
	const theme = useTheme()
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"))

	return (
		<div css={styles(theme, isDesktop)}>
			{/* <Backdrop open={open} onClick={onClick}> */}
				<Box
					className={`nav-mobile nav-mobile-${visibility ? "show" : "hide"}`}>
					<Container>
						<Stack direction="column" spacing={5} className="nav-mobile-wrapper">
							{navLinks?.map((item, index) => (
								<Link to={item?.href ?? '/'} className={"nav-mobile-wrapper-link"} key={index}>
									<Typography
										className={"nav-mobile-wrapper-link-text"}
										key={index}
										variant="h3">
										{item.name}
									</Typography>
								</Link>
							))}
						</Stack>
					</Container>
				</Box>
			{/* </Backdrop> */}
		</div>
	)
}

export default NavbarMobile
