import {css as styled} from "@emotion/react"

const styling = (theme) => styled`

    @font-face {
        font-family: 'Comfortaa';
        src: url('/fonts/Comfortaa-Medium.ttf') format('ttf');
    }

    background-color: #1F212D;
    padding: ${theme.spacing(20, 15)};
    z-index: 5;

    ${theme.breakpoints.down('lg')} {
        padding: ${theme.spacing(8)};
    }

    ${theme.breakpoints.down('sm')} {
        padding: ${theme.spacing(8, 4)};
    }

    .footer {
        flex-wrap: nowrap;
        font-family: 'Comfortaa', sans-serif !important;
    }

    .footer-icon {
        color: #EB5558;
        width: 50px;
        height: 50px;
    }

    .footer-contact {
        color: #fff;
        padding-top: ${theme.spacing(2)};
        font-family: 'Comfortaa', sans-serif !important;
        font-weight: 400;
        font-size: 1.2rem;
    }

    .contact-link {
        text-decoration: none;
        color: #fff;
    }

    .footer-bottom {
        font-size: 0.8rem;
        color: #fff;
        opacity: 0.8;
        padding-top: ${theme.spacing(1)};
    }

    .footer-links {
        ${theme.breakpoints.down('md')} {
            flex-direction: column;
            align-content: flex-start;
        }
    }

    .footer-links > * {
        text-decoration: none;
        color: #fff;
        font-size: 0.8rem;
        font-weight: 100;
        opacity: 0.8;
        margin-right: ${theme.spacing(4)};
        display: flex;
        align-items: flex-end;

        ${theme.breakpoints.down('md')} {
            align-self: flex-start;
            margin-top: ${theme.spacing(0.5)};
        }
    }

    .item-logo {
        position: relative;
        bottom: -16px;
    }

    .logo-dot {
        font-family: Butler, serif;
        color: #fff;
        font-size: 4.5rem;
        position: relative;
        top: -64px;
        left: -10px;
        height: 20px;
    }

    ${theme.breakpoints.down('md')} {
        .logo {
            padding-top: ${theme.spacing(4)};
        }
    }

    ${theme.breakpoints.down('sm')} {
        .footer-links > * {
            margin-bottom: ${theme.spacing(2)};
        }
    }

`

export default styling