import React from 'react';
import styles from './styles';
import Navbar from '../Navbar';
import Footer from '../Footer/index';
import ReactHelmet from 'react-helmet';

import { Box, createTheme, ThemeProvider } from '@mui/material';
import { Global } from '@emotion/react';

import { library } from '@fortawesome/fontawesome-svg-core';

import {
	faFacebook,
	faInstagram,
	faGithub,
	faTwitter,
	faTiktok,
	faGoogle,
	faLinkedin
} from '@fortawesome/free-brands-svg-icons';

import { faChevronDown } from '@fortawesome/pro-light-svg-icons';

library.add(
	faChevronDown,
	faFacebook,
	faInstagram,
	faGithub,
	faTwitter,
	faTiktok,
	faTwitter,
	faGoogle,
	faLinkedin
);

// if (process.env.NODE_ENV === 'development') {
// 	const whyDidYouRender = require('@welldone-software/why-did-you-render');
// 	whyDidYouRender(React, {
// 		trackAllPureComponents: true
// 	});
// }

const Layout = ({ children }) => {
	const theme = createTheme({
		palette: {
			primary: {
				main: '#70B2D0'
			}
		},
		typography: {
			fontFamily: ['itc-avant-garde-gothic-pro', 'sans-serif'].join(', ')
		},
		components: {
			MuiButton: {
				variants: [
					{
						props: {
							variant: 'contained',
							color: 'primary'
						},
						style: {
							background: '#70B2D0',
							transition: 'background-color 0.3s ease-in',
							padding: '14px 24px 9px',
							fontSize: '16px',
							borderRadius: 0,
							fontWeight: 300,
							color: 'white',
							fontFamily: ['itc-avant-garde-gothic-pro', 'sans-serif'].join(', '),

							'&:hover, &:focus': {
								background: '#497589',
								color: 'white'
							}
						}
					}
				]
			}
		}
	});

	return (
		<ThemeProvider theme={theme}>
			<ReactHelmet>
				<script type="text/javascript" src="https://secure.visionary-enterprise-wisdom.com/js/269059.js" ></script>
            </ReactHelmet>
			<noscript><img alt="" src="https://secure.visionary-enterprise-wisdom.com/269059.png" style={{display: 'none'}} /></noscript>
			<Global styles={styles(theme)} />
			<Box css={styles(theme)}>
				<Navbar />
				<main>{children}</main>
				<Footer />
			</Box>

		</ThemeProvider>
	);
};

export default Layout;
