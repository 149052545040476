import * as React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styles from './styles';
import { useTheme } from '@mui/material/styles';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagramSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('md'));

	const data = useStaticQuery(graphql`
		query Footer {
			markdownRemark(frontmatter: { templateKey: { eq: "home" } }) {
				frontmatter {
					footer {
						email
						phone
						address
						lineOne
						lineTwo
						linkOne
						linkTwo
						copyright
						logoImage
					}
				}
			}
		}
	`);

	return (
		<div css={styles(theme)}>
			<Grid container className="footer" direction={mobile ? 'column' : 'row'} nowrap>
				<Grid item container direction="column">
					<Grid item container direction="row">
						<Link to="https://www.linkedin.com/company/play-retail/" target="_blank">
							<FontAwesomeIcon className="footer-icon" icon={faLinkedin} />
						</Link>
						<Link to="https://www.instagram.com/playretail/?hl=en" target="_blank">
							<FontAwesomeIcon className="footer-icon" icon={faInstagramSquare} />
						</Link>
					</Grid>
					<Grid item pt={2}>
						<Typography className="footer-contact">
							<a
								className="contact-link"
								href={`mailto:${data?.markdownRemark?.frontmatter.footer.email}`}
							>
								{data?.markdownRemark?.frontmatter.footer.email}
							</a>
						</Typography>
						<Typography className="footer-contact">
							<a
								className="contact-link"
								href={`tel:${data?.markdownRemark?.frontmatter.footer.phone}`}
							>
								{data?.markdownRemark?.frontmatter.footer.phone}
							</a>
						</Typography>
						<Typography className="footer-contact">
							{data?.markdownRemark?.frontmatter.footer.address}
						</Typography>
					</Grid>
					<Grid item pt={4}>
						<Typography className="footer-bottom" variant="body2">
							{data?.markdownRemark?.frontmatter.footer.lineOne}
						</Typography>
						<Typography className="footer-bottom" variant="body2">
							{data?.markdownRemark?.frontmatter.footer.lineTwo}
						</Typography>
					</Grid>
					<Grid item container pt={4} alignItems="flex-end" className="footer-links">
						<Link to="/terms-and-conditions">
							{data?.markdownRemark?.frontmatter.footer.linkOne}
						</Link>
						<Link to="/privacy-policy">
							{data?.markdownRemark?.frontmatter.footer.linkTwo}
						</Link>
						<Typography sx={{ position: 'relative', top: !mobile ? '3px' : 0 }}>
							{data?.markdownRemark?.frontmatter.footer.copyright}
						</Typography>
					</Grid>
				</Grid>
				<Grid
					item
					container
					justifyContent={mobile ? 'flex-start' : 'flex-end'}
					alignItems="flex-end"
					className="item-logo"
				>
					<img className="logo" src="/img/play-coral.svg" alt="Play" width="127" />
					{/* <span className="logo-dot">.</span> */}
				</Grid>
			</Grid>
		</div>
	);
};

export default Footer;
