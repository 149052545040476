import React, { useContext } from 'react';
import { GatsbyImage /*, GatsbyImageProps*/ } from 'gatsby-plugin-image';

// import { File } from '@models/cms';
import { CMSContext } from '../../helpers/contexts';

// type GatsbyImageType = Omit<GatsbyImageProps, 'image' | 'css'> & { src: File };
// type ImageType = Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'css'>;

const Image /*: React.FC<{ alt: string } & (GatsbyImageType | ImageType)>*/ = props => {
	const { getAsset } = useContext(CMSContext);
	const { alt } = props;

	if (typeof props.src === 'string')
		return (
			<img
				{
					/**{...(props as ImageType)}**/ ...props
				}
				src={getAsset?.(props.src)?.url ?? '#'}
			/>
		);

	if (!props.src?.childImageSharp?.gatsbyImageData) return null;

	return (
		<GatsbyImage
			{
				/**{...(props as GatsbyImageType)}**/ ...props
			}
			alt={alt}
			image={props.src.childImageSharp.gatsbyImageData}
		/>
	);
};

export default Image;
