import {css as styled} from "@emotion/react"
import {alpha} from "@mui/material"

const styling = (theme) => styled`
	
	@import url('http://fonts.cdnfonts.com/css/butler');
	top: 0;
	right: 0;
	position: fixed;
	left: 0;
	z-index: 100;
	background-color: #1F212D;
	user-select: none;
	//box-shadow: 0 12px 10px rgba(0, 0, 0, 0.1);

	.nav {
		align-items: center;
		width: 100%;
		z-index: 101;

		${theme.breakpoints.up('xl')} {
			max-width: 100vw;
		}

		.nav-container {
			display: flex;
			justify-content: flex-end;
			max-width: 100vw;
			width: 100%;
		}

		.nav-wrapper {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			position: relative;
			margin-left: 16px;

			${theme.breakpoints.down('sm')} {
				margin-left: 20px;
			}

			.nav-bar__balancer {
				margin-right: auto;
				width: 20px;
				visibility: hidden;
			}

			.nav-wrapper-logo {
				margin-right: 54px;
				align-self: center;
				display: flex;
				align-items: center;
				margin-left: auto;
				z-index: 101;
				color: #fff;

				.nav-bar__icon {
					z-index: 100;
					color: #fff;
				}

			}

			.nav-wrapper-item {
				text-decoration: none;

				&.nav-wrapper-item-text {
					color: #222 !important;

					&.activeClass {
						color: ${alpha(theme.palette.text.primary, 0.5)} !important;
					}
				}

				&.nav-wrapper-item-button {
					background: #70B2D0;
					transition: background-color 0.3s ease-in;
					padding: 14px 24px 9px;
					font-size: 16px;
					border-radius: 0;
					font-weight: 300;
					color: white;
					font-family: "Josefin Sans", sans-serif;

					&:hover {
						background: #497589;
					}

				}
			}

			.nav-wrapper-desktop {
				width: 100%;
				margin: auto;
				align-items: center;
				justify-content: space-between;

				.nav-wrapper-desktop-logo-container {
				}

				.nav-wrapper-desktop-links-container {
					a {
						margin: 0 1vw;
					}
				}
			}
		}
	}

	.menu-icon {
		opacity: 1;
		transition: 0.3s ease;
	}

	.menu-icon:hover {
		opacity: 0.5;
	}

	.logo {
		user-select: none;
		position: relative;
		top: 4px;
	}

	.logo-dot {
		font-family: Butler, serif;
		color: #E95659;
		font-size: 2rem;
		text-decoration: none;
		position: relative;
		top: -5px;
		left: -5px;
		height: 20px;
	}

	.logo-link {
		text-decoration: none;
	}

	font-family: "Josefin Sans", sans-serif;
`

export default styling
